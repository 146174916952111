export const CONTENT_BLOCK_TYPE_ARTICLE = 'article'
export const CONTENT_BLOCK_TYPE_VIDEO_ARTICLE = 'videoArticle'
export const CONTENT_BLOCK_TYPE_VIDEO = 'video'

export const CONTENT_BLOCK_TYPE_DEFAULT = CONTENT_BLOCK_TYPE_ARTICLE

export default {
  computed: {
    contentBlockTypeValues () {
      return [
        {
          id: CONTENT_BLOCK_TYPE_ARTICLE,
          title: this.$t('contentBlock.content_block_type_value.article')
        },
        {
          id: CONTENT_BLOCK_TYPE_VIDEO_ARTICLE,
          title: this.$t('contentBlock.content_block_type_value.video_article')
        },
        {
          id: CONTENT_BLOCK_TYPE_VIDEO,
          title: this.$t('contentBlock.content_block_type_value.video')
        }
      ]
    }
  }
}
